<template>
  <v-card>
    <v-toolbar
      color="secondary"
      dark
      flat
      dense
    >
      <v-spacer />
      <v-toolbar-title v-text="$t('multitask.playing.choiceList')" />
      <v-spacer />
    </v-toolbar>

    <v-card-text>
      <v-card
        v-for="(group, groupIndex) in groups"
        :key="`group-${groupIndex}`"
        class="my-4"
        max-width="450"
        outlined
      >
        <v-card-text>
          <v-row no-gutters justify="center">
            <v-col
              v-for="(item, itemIndex) in group"
              :key="itemIndex"
              cols="auto"
            >
              <v-btn
                outlined
                color="secondary"
                :disabled="item.used"
                class="ma-2"
                width="40"
                height="40"
                @click="onClick(item)"
              >
                <span
                  v-if="item.text"
                  style="font-size: 22px; font-weight: 400;"
                  v-text="item.text"
                />
                <v-icon v-if="item.icon" v-text="item.icon" />
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import Helpers from 'src/utils/helpers'

const ITEM_TYPES = {
  letter: 'letter',
  number: 'number',
  shape: 'shape',
}

export default {
  name: 'ChoiceList',
  props: {
    lengthOfChoiceList: {
      type: Number,
      default: 10,
    },
    validator: {
      type: Function,
      default: () => true,
    },
    iconList: {
      type: Array,
      required: true,
    },
    typeOrder: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    groups: null,
  }),
  mounted () {
    this.groups = this.typeOrder
      .map(type => Helpers.shuffleArray(this.getListBuilder(type)()))
  },
  methods: {
    onClick (item) {
      if (this.validator(item)) {
        item.used = true
      }
    },
    getListBuilder (type) {
      return {
        [ITEM_TYPES.letter]: this.buildLetterList,
        [ITEM_TYPES.number]: this.buildNumberList,
        [ITEM_TYPES.shape]: this.buildShapeList,
      }[type]
    },
    buildLetterList () {
      return Helpers.createArray(this.lengthOfChoiceList, index => ({
        type: ITEM_TYPES.letter,
        value: index,
        text: String.fromCharCode(65 + index),
        used: false,
      }))
    },
    buildNumberList () {
      return Helpers.createArray(this.lengthOfChoiceList, index => ({
        type: ITEM_TYPES.number,
        value: index,
        text: `${index + 1}`,
        used: false,
      }))
    },
    buildShapeList () {
      return Helpers.createArray(this.lengthOfChoiceList, index => ({
        type: ITEM_TYPES.shape,
        value: index % this.iconList.length,
        icon: this.iconList[index % this.iconList.length],
        used: false,
      }))
    },
  },
}
</script>
