<template>
  <v-container>
    <v-overlay :value="countdownOverlay" absolute>
      <div
        class="accent pa-6 headline rounded"
        v-text="countdownOverlay"
      />
    </v-overlay>

    <v-overlay :value="mistakeOverlay" absolute>
      <div
        class="error pa-6 headline rounded"
        v-text="$t('multitask.playing.mistakeMessage')"
      />
    </v-overlay>

    <v-overlay :value="finished" absolute>
      <div
        class="accent pa-6 headline rounded"
        v-text="$t('multitask.playing.finished')"
      />
    </v-overlay>

    <v-alert
      prominent
      dark
      text
      color="secondary"
      icon="mdi-information"
      class="headline text-center"
    >
      {{ $t(`multitask.playing.${gameName}.informations`) }}
    </v-alert>

    <v-row justify="center">
      <v-col cols="auto">
        <choice-list
          :length-of-choice-list="game.lengthOfChoiceList"
          :icon-list="$options.shapeIconList"
          :validator="onPlayerMakeChoice"
          :type-order="$options.typeOrder"
        />
      </v-col>
      <v-col cols="auto">
        <result-list
          :value="selectedValues"
          :icon-list="$options.shapeIconList"
          :type-order="$options.typeOrder"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import _last from 'lodash/last'
import _map from 'lodash/map'
import _values from 'lodash/values'
import Helpers from 'src/utils/helpers'
import ChoiceList from '../components/ChoiceList'
import ResultList from '../components/ResultList'
import AVAILABLE_ACTIONS from '../availableActions'

const TYPE_ORDER = ['letter', 'number', 'shape']
const SHAPE_ICON_LIST = [
  'mdi-square-outline',
  'mdi-circle-outline',
  'mdi-triangle-outline',
]
const NUMBER_VALUES_BY_TYPE = {
  letter: 24,
  number: Infinity,
  shape: SHAPE_ICON_LIST.length,
}

export default {
  name: 'PlayingContainer',
  components: {
    ChoiceList,
    ResultList,
  },
  props: {
    game: {
      type: Object,
      required: true,
    },
    playerId: {
      type: String,
      required: true,
    },
    countdown: {
      type: Number,
      default: 5,
    },
  },
  inject: [
    'sendPlayerAction',
  ],
  data: () => ({
    internalCountdown: null,
    mistakeOverlay: false,
    numberOfMistakes: 0,
    timer: new Helpers.Timer(),
    finished: false,
    selectedValues: TYPE_ORDER
      .reduce((acc, type) => ({ ...acc, [type]: [] }), {}),
  }),
  computed: {
    ...mapGetters('session', {
      gameNameListByResourceId: 'gameNameListByResourceId',
    }),
    gameName () {
      return this.gameNameListByResourceId[this.game.id]
    },
    countdownOverlay () {
      const countdown = this.internalCountdown
      return this.internalCountdown !== 0
        && this.$tc('multitask.playing.countdown', countdown, { countdown })
    },
    nextType () {
      if (this.game.type === 'sequential') {
        return TYPE_ORDER
          .find(type => this.selectedValues[type].length < this.game.lengthOfChoiceList)
      }

      const maxSelectedInType = Math.max(
        ..._map(_values(this.selectedValues), 'length'),
      )
      const firstTypeWithMissingValue = TYPE_ORDER
        .find(type => this.selectedValues[type].length < maxSelectedInType)

      return firstTypeWithMissingValue || TYPE_ORDER[0]
    },
    nextValue () {
      const currentValue = _last(this.selectedValues[this.nextType]) ?? -1
      return (currentValue + 1) % NUMBER_VALUES_BY_TYPE[this.nextType]
    },
  },
  mounted () {
    this.startCountdown()
  },
  methods: {
    startCountdown () {
      this.internalCountdown = this.countdown
      const countdownInterval = setInterval(() => {
        this.internalCountdown -= 1
        if (!this.internalCountdown) {
          clearInterval(countdownInterval)
          this.timer.start()
        }
      }, 1000)
    },
    isTypeCompleted (type) {
      return this.selectedValues[type].length === this.game.lengthOfChoiceList
    },
    checkIfGameIsDone () {
      if (TYPE_ORDER.every(this.isTypeCompleted)) {
        const duration = this.timer.stop()
        this.finished = true
        this.sendPlayerAction(AVAILABLE_ACTIONS.finishGame, {
          timePlayed: Math.round(duration / 1000),
          numberOfMistakes: this.numberOfMistakes,
        })
      }
    },
    onPlayerMakeAMistake () {
      this.mistakeOverlay = true
      this.numberOfMistakes += 1
      setTimeout(() => {
        this.mistakeOverlay = false
      }, this.game.mistakePenality * 1000)
    },
    onPlayerMakeChoice ({ type, value }) {
      if (type !== this.nextType || value !== this.nextValue) {
        return this.onPlayerMakeAMistake()
      }

      this.selectedValues[type].push(value)
      this.checkIfGameIsDone()
      return true
    },
  },
  shapeIconList: SHAPE_ICON_LIST,
  typeOrder: TYPE_ORDER,
}
</script>
