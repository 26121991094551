<template>
  <v-card min-width="248px">
    <v-toolbar
      color="secondary"
      dark
      flat
      dense
    >
      <v-spacer />
      <v-toolbar-title v-text="$t('multitask.playing.resultList')" />
      <v-spacer />
    </v-toolbar>
    <v-card-text>
      <v-row
        v-for="(cols, groupIndex) in rows"
        :key="`group-${groupIndex}`"
        no-gutters
      >
        <v-col
          v-for="(item, itemIndex) in cols"
          :key="itemIndex"
          cols="auto"
        >
          <v-btn
            outlined
            color="secondary"
            disabled
            class="ma-1"
            width="40"
            height="40"
          >
            <span
              v-if="item.text"
              style="font-size: 22px; font-weight: 400;"
              v-text="item.text"
            />
            <v-icon v-if="item.icon" v-text="item.icon" />
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import _map from 'lodash/map'
import _values from 'lodash/values'
import Helpers from 'src/utils/helpers'

export default {
  name: 'ResultList',
  props: {
    value: {
      type: Object,
      required: true,
    },
    iconList: {
      type: Array,
      required: true,
    },
    typeOrder: {
      type: Array,
      required: true,
    },
  },
  computed: {
    numberOfRows () {
      return Math.max(..._map(_values(this.value), 'length'), 1)
    },
    rows () {
      return Helpers.createArray(
        this.numberOfRows,
        index => this.typeOrder.map(type => this.getValue(type, index)),
      ).reverse()
    },
  },
  methods: {
    getValue (type, index) {
      const value = this.value[type][index]
      if (!value && value !== 0) {
        return ''
      }
      switch (type) {
        case 'letter':
          return { text: String.fromCharCode(65 + value) }
        case 'shape':
          return { icon: this.iconList[value] }
        case 'number':
          return { text: value + 1 }
        default:
          return ''
      }
    },
  },
}
</script>
